const baseUrl = process.env.REACT_APP_TE_API_URL;

console.log("TechExtend API:", process.env.REACT_APP_TE_API_URL);

export async function postToTechExtend(path, data) {
    const res = await retryFetch(`${baseUrl}${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  
    return res.json();
  }
  
export async function getFromTechExtend(path) {
    const res = await retryFetch(`${baseUrl}${path}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return res.json();
  }

// Basic retry wrapper
async function retryFetch(url, options, retries = 3, delay = 1000) {
    for (let i = 0; i < retries; i++) {
      try {
        const res = await fetch(url, options);
        if (!res.ok) throw new Error(`HTTP ${res.status}`);
        return res;
      } catch (err) {
        if (i < retries - 1) {
          await new Promise(resolve => setTimeout(resolve, delay));
        } else {
          throw err;
        }
      }
    }
    throw new Error('Unexpected error in retryFetch');
  }
  
  