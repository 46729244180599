// src/components/SignInModal.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithPopup, GoogleAuthProvider, GithubAuthProvider } from 'firebase/auth';
import './SignInModal.css';

import { postToTechExtend } from '../utils/api'; // adjust path as needed


const SignInModal = ({ showModal, setShowModal }) => {
  const navigate = useNavigate();
  const auth = getAuth();

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
  
      // 1. Send user data to TechExtend
      await postToTechExtend('/api/users', {
        email: user.email,
        name: user.displayName,
        source: 'quizzo',
      });
      console.log(user.email, user.displayName, 'quizzo')

      // 2. Close modal and navigate
      setShowModal(false);
      navigate('/');
    } catch (error) {
      console.error('Sign-in error:', error);
    }
  };

  const handleGithubSignIn = async () => {
    const provider = new GithubAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      setShowModal(false);
      navigate('/');
    } catch (error) {
      console.error(error);
    }
  };

  if (!showModal) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Sign In</h2>
        <button onClick={handleGoogleSignIn} className="sign-in-button">Sign in with Google</button>
        {/* <button onClick={handleGithubSignIn} className="sign-in-button">Sign in with GitHub</button> */}
        <button onClick={() => setShowModal(false)} className="close-button">Close</button>
      </div>
    </div>
  );
};

export default SignInModal;
